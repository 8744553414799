import React, { useState, useRef, useEffect, useContext } from "react";
import StelarBackground from "./StelarBackground";
import { UserContext } from "../context/UserContext";
import { RiCoinFill } from "react-icons/ri";
import { FaTicketAlt, FaInfoCircle } from "react-icons/fa";
import { BsFillInboxFill } from "react-icons/bs";

const Stelar = () => {
  const gameRef = useRef(null);
  const shipRef = useRef(null);
  const [meteorites, setMeteorites] = useState([]);
  const [anchorState, setAnchorState] = useState({
    isLaunched: false,
    startX: 0,
    startY: 0,
    currentX: 0,
    currentY: 0,
    endX: 0,
    endY: 0,
    angle: 0,
    capturedMeteorite: null,
    returnSpeed: 5,
    isReturning: false,
  });

  const [progress, setProgress] = useState(0);
  const {
    updateStarBalance,
    updateRocketTicket,
    updateContainers,
    user,
    updateUser,
  } = useContext(UserContext); // Accesăm funcția din context
  const [startTime, setStartTime] = useState(null); // Adăugăm timpul de start
  const [rewardMessages, setRewardMessages] = useState([]);
  const [showNoTicketsMessage, setShowNoTicketsMessage] = useState(false);
  const [totalCasesEarned, setTotalCasesEarned] = useState(0);
  const [totalTicketsEarned, setTotalTicketsEarned] = useState(0);

  const showRewardMessage = (amount) => {
    const id = Date.now(); // ID unic pentru fiecare mesaj
    setRewardMessages((prev) => [...prev, { id, amount }]);

    // Elimină mesajul după 2 secunde
    setTimeout(() => {
      setRewardMessages((prev) => prev.filter((msg) => msg.id !== id));
    }, 2000);
  };

  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isGameEnded, setIsGameEnded] = useState(false);
  const [totalTokensEarned, setTotalTokensEarned] = useState(0);

  const startGame = () => {
    // Verificăm dacă utilizatorul are suficiente bilete
    if (user.rocketTicket < 1) {
      showTemporaryMessage(setShowNoTicketsMessage); // Afișăm mesajul de eroare
      return; // Oprim funcția dacă nu sunt suficiente bilete
    }

    // Scădem un bilet din contul utilizatorului
    updateUser({
      rocketTicket: user.rocketTicket - 1, // Eliminăm un bilet
    });

    // Inițializăm starea jocului
    setIsGameStarted(true);
    setIsGameEnded(false);
    setProgress(0);
    setTotalTokensEarned(0); // Resetăm câștigurile rundei
    setTotalCasesEarned(0);
    setTotalTicketsEarned(0);

    // Pornim jocul și calculăm progresul
    const gameStartTime = Date.now();
    setStartTime(gameStartTime);

    const roundDuration = 2 * 60 * 1000; // Durata rundei: 2 minute
    const progressInterval = setInterval(() => {
      const elapsedTime = Date.now() - gameStartTime;
      const progressPercentage = Math.min(
        (elapsedTime / roundDuration) * 100,
        100
      );
      setProgress(progressPercentage);

      // Dacă progresul ajunge la 100%, încheiem jocul
      if (progressPercentage >= 100) {
        clearInterval(progressInterval);
        setIsGameEnded(true); // Terminăm jocul
        setIsGameStarted(false);
      }
    }, 100);
  };

  // Funcție auxiliară pentru afișarea mesajelor temporare
  const showTemporaryMessage = (setMessageFunction) => {
    setMessageFunction(true);
    setTimeout(() => {
      setMessageFunction(false);
    }, 5000); // Mesajul dispare după 5 secunde
  };

  const METEORITE_TYPES = [
    {
      id: "star-crystal-1",
      starBalance: 15,
      spawnChance: 2,
      image: "/rock-meteorite.png",
      speed: 1,
    },
    {
      id: "star-crystal-2",
      starBalance: 50,
      spawnChance: 0.5,
      image: "/gold-meteorite.png",
      speed: 2,
    },
    {
      id: "bomb",
      starBalance: 30, // Fără minus, se gestionează în logică
      spawnChance: 0.15,
      image: "/bomb.png",
      speed: 1.5,
    },
    {
      id: "gift",
      rewards: [
        { type: "rocketTicket", amount: 1, chance: 0.08 },
        { type: "containers", amount: 1, chance: 0.05 },
        { type: "starBalance", amount: 200, chance: 2 },
        { type: "starBalance", amount: 300, chance: 0.3 },
        { type: "starBalance", amount: 500, chance: 0.2 },
      ],
      spawnChance: 0.08,
      image: "/gift.png",
      speed: 3,
    },
  ];

  const handleGiftReward = (gift) => {
    // Normalizăm șansele pentru a ne asigura că suma lor nu depășește 1
    const totalChance = gift.rewards.reduce(
      (sum, reward) => sum + reward.chance,
      0
    );
    const normalizedRewards = gift.rewards.map((reward) => ({
      ...reward,
      chance: reward.chance / totalChance,
    }));

    // Generăm un număr random între 0 și 1
    const random = Math.random();

    // Folosim tehnica "weight accumulation" pentru selecție
    let accumulatedChance = 0;

    for (const reward of normalizedRewards) {
      accumulatedChance += reward.chance;

      if (random <= accumulatedChance) {
        // Am găsit recompensa câștigătoare
        if (reward.type === "starBalance") {
          updateStarBalance(reward.amount);
          showRewardMessage(`+${reward.amount} $STAR`);
          setTotalTokensEarned((prev) => prev + reward.amount);
        } else if (reward.type === "rocketTicket") {
          updateRocketTicket(reward.amount);
          showRewardMessage(`+${reward.amount} Ticket`);
          setTotalTicketsEarned((prev) => prev + reward.amount);
        } else if (reward.type === "containers") {
          updateContainers(reward.amount);
          showRewardMessage(`+${reward.amount} Case`);
          setTotalCasesEarned((prev) => prev + reward.amount);
        }
        return; // Ieșim din funcție după ce am acordat recompensa
      }
    }
  };

  const handleBombReward = (bomb) => {
    const penalty = -bomb.starBalance; // Scădem punctele
    updateStarBalance(penalty);
    showRewardMessage(`${penalty} $STAR`); // Mesaj cu valoarea negativă
    setTotalTokensEarned((prev) => prev + penalty); // Actualizăm totalul
  };

  const calculateAnchorTrajectory = (event) => {
    if (!gameRef.current || anchorState.isLaunched) return;

    const gameRect = gameRef.current.getBoundingClientRect();
    const shipRect = shipRef.current.getBoundingClientRect();

    const shipCenterX = shipRect.left + shipRect.width / 2 - gameRect.left;
    const shipCenterY = shipRect.top + shipRect.height / 2 - gameRect.top;

    const mouseX = event.clientX - gameRect.left;
    const mouseY = event.clientY - gameRect.top;

    const angle = Math.atan2(mouseY - shipCenterY, mouseX - shipCenterX);

    const endX = mouseX;
    const endY = mouseY;

    setAnchorState((prev) => ({
      ...prev,
      isLaunched: true,
      isReturning: false,
      startX: shipCenterX,
      startY: shipCenterY,
      currentX: shipCenterX,
      currentY: shipCenterY,
      endX: endX,
      endY: endY,
      angle: angle,
      returnSpeed: 10,
    }));
  };

  const spawnMeteorites = () => {
    if (!gameRef.current) return;

    const gameRect = gameRef.current.getBoundingClientRect();

    // Definim limitele verticale pentru spawn
    const minY = gameRect.height * 0.2; // Începe la 30% din înălțimea jocului
    const maxY = gameRect.height * 1; // Nu depășește 80% din înălțimea jocului

    const newMeteorites = METEORITE_TYPES.filter(
      (meteorite) => Math.random() < meteorite.spawnChance
    ).map((meteorite) => ({
      ...meteorite,
      x: gameRect.width, // Spawn at the right edge of the game area
      y: minY + Math.random() * (maxY - minY), // Poziție Y aleatorie în intervalul definit
      width: 40,
      height: 40,
      captured: false,
      speed: meteorite.speed, // Setăm viteza unică
    }));

    setMeteorites((prev) => [...prev, ...newMeteorites]);
  };

  const updateMeteorites = () => {
    setMeteorites((prev) =>
      prev
        .map((meteorite) => {
          if (meteorite.captured) {
            return meteorite; // Nu actualizăm poziția meteoriților capturați
          }
          return {
            ...meteorite,
            x: meteorite.x - meteorite.speed,
          };
        })
        .filter((meteorite) => meteorite.x > -50)
    );
  };

  const isColliding = (rect1, rect2) => {
    return (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
    );
  };

  useEffect(() => {
    if (!anchorState.isLaunched) return;

    const moveAnchor = () => {
      const distanceToTarget = Math.sqrt(
        Math.pow(anchorState.currentX - anchorState.endX, 2) +
          Math.pow(anchorState.currentY - anchorState.endY, 2)
      );

      if (distanceToTarget <= 10 && !anchorState.isReturning) {
        setAnchorState((prev) => ({
          ...prev,
          isReturning: true,
        }));
      }

      if (anchorState.isReturning) {
        const distanceToShip = Math.sqrt(
          Math.pow(anchorState.currentX - anchorState.startX, 2) +
            Math.pow(anchorState.currentY - anchorState.startY, 2)
        );

        if (distanceToShip <= 10) {
          if (anchorState.capturedMeteorite) {
            if (anchorState.capturedMeteorite.id === "gift") {
              handleGiftReward(anchorState.capturedMeteorite);
            } else if (anchorState.capturedMeteorite.id === "bomb") {
              handleBombReward(anchorState.capturedMeteorite); // Gestionăm bomba
            } else {
              const earnedTokens = anchorState.capturedMeteorite.starBalance;
              updateStarBalance(earnedTokens);
              showRewardMessage(`+${earnedTokens} $STAR`);
              setTotalTokensEarned((prev) => prev + earnedTokens);
            }
          }

          setAnchorState({
            isLaunched: false,
            startX: 0,
            startY: 0,
            currentX: 0,
            currentY: 0,
            endX: 0,
            endY: 0,
            angle: 0,
            capturedMeteorite: null,
            isReturning: false,
            returnSpeed: 5,
          });

          setMeteorites((prev) => prev.filter((m) => !m.captured));
          return;
        }

        const returnStep = anchorState.capturedMeteorite ? 3 : 10;
        const newX =
          anchorState.currentX - Math.cos(anchorState.angle) * returnStep;
        const newY =
          anchorState.currentY - Math.sin(anchorState.angle) * returnStep;

        setAnchorState((prev) => ({
          ...prev,
          currentX: newX,
          currentY: newY,
          capturedMeteorite: prev.capturedMeteorite
            ? {
                ...prev.capturedMeteorite,
                x: newX,
                y: newY,
              }
            : null,
        }));
      } else {
        const launchStep = 10;
        const newX =
          anchorState.currentX + Math.cos(anchorState.angle) * launchStep;
        const newY =
          anchorState.currentY + Math.sin(anchorState.angle) * launchStep;

        const collidedMeteorite = meteorites.find((meteorite) =>
          isColliding(
            { x: newX, y: newY, width: 5, height: 5 },
            {
              x: meteorite.x,
              y: meteorite.y,
              width: meteorite.width,
              height: meteorite.height,
            }
          )
        );

        if (collidedMeteorite) {
          setAnchorState((prev) => ({
            ...prev,
            capturedMeteorite: collidedMeteorite,
            isReturning: true,
          }));

          setMeteorites((prev) =>
            prev.map((m) =>
              m === collidedMeteorite ? { ...m, captured: true } : m
            )
          );
        } else {
          setAnchorState((prev) => ({
            ...prev,
            currentX: newX,
            currentY: newY,
          }));
        }
      }
    };

    const movementInterval = setInterval(moveAnchor, 16);
    return () => clearInterval(movementInterval);
  }, [anchorState, meteorites, updateStarBalance]);

  useEffect(() => {
    const spawnInterval = setInterval(spawnMeteorites, 3000);
    const moveInterval = setInterval(updateMeteorites, 50);

    return () => {
      clearInterval(spawnInterval);
      clearInterval(moveInterval);
    };
  }, []);

  useEffect(() => {
    if (!startTime) return; // Asigură-te că startTime este definit

    const roundDuration = 2 * 60 * 1000; // 2 minute în milisecunde
    const progressInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime; // Folosești timpul salvat
      const progressPercentage = Math.min(
        (elapsedTime / roundDuration) * 100,
        100
      );
      setProgress(progressPercentage);

      if (progressPercentage >= 100) {
        clearInterval(progressInterval);
      }
    }, 100);

    return () => clearInterval(progressInterval);
  }, [startTime]); // Adaugă startTime ca dependență

  const endGame = () => {
    setIsGameEnded(true);
    setIsGameStarted(false);
  };

  return (
    <div className="game-display-2">
      <div className="rocket-game-stats">
        <div className="stat-box" style={{ justifyContent: "end" }}>
          <p className="stat-label">
            <RiCoinFill />
            Balance:
          </p>
          <p className="stat-value">
            {user.starBalance.toLocaleString()} $STAR
          </p>
        </div>
        <div className="stat-box">
          <p className="stat-label">
            <FaTicketAlt />
            Tickets:
          </p>
          <p className="stat-value">{user.rocketTicket}</p>
        </div>
      </div>

      {!isGameStarted && !isGameEnded && (
        <section className="stelar-game-display-2">
          {showNoTicketsMessage && (
            <div
              className={`promo-message ${
                showNoTicketsMessage ? "show" : "hide"
              }`}
              style={{
                color: "white",
                fontWeight: "500",
                background: "#31313198",
              }}
            >
              <FaInfoCircle style={{ verticalAlign: "middle" }} />
              Not enough tickets!
            </div>
          )}
          <StelarBackground />
          <div className="start-screen">
            <h1 className="start-title">Stellar Mines</h1>
            <button className="start-button" onClick={startGame}>
              Play for 1 Ticket
            </button>
          </div>
        </section>
      )}

      {isGameEnded && (
        <section className="stelar-game-display-2">
          <StelarBackground />
          <div className="end-screen">
            <h1 className="end-title">Mission Complete!</h1>
            <h2 className="stats-title">Loot Collected:</h2>
            <div className="stats-container">
              {totalTokensEarned > 0 && (
                <p className="stats-value">
                  <RiCoinFill style={{ marginLeft: 0 }} /> {totalTokensEarned}
                  <BsFillInboxFill /> {totalCasesEarned}
                  <FaTicketAlt /> {totalTicketsEarned}
                </p>
              )}
            </div>
            <button
              className="start-button"
              onClick={() => setIsGameEnded(false)}
            >
              Back to game
            </button>
          </div>
        </section>
      )}

      {isGameStarted && (
        <section
          ref={gameRef}
          className="stelar-game-display-2"
          onClick={calculateAnchorTrajectory}
        >
          {" "}
          <div className="progress-stelar-container">
            <div
              className="progress-stelar-bar"
              style={{
                width: `${progress}%`,
                height: "100%",
                transition: "width 0.1s linear",
              }}
            ></div>
          </div>
          <div className="reward-messages-container">
            {rewardMessages.map((msg) => (
              <div key={msg.id} className="reward-message">
                {msg.amount > 0 ? `+${msg.amount}` : `${msg.amount}`}
              </div>
            ))}
          </div>
          <StelarBackground />
          <div
            ref={shipRef}
            className="stelar-ship"
            style={{
              backgroundImage: "url(/ship.gif)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          {anchorState.isLaunched && (
            <svg
              className="stelar-anchor"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            >
              <defs>
                <pattern
                  id="rope-pattern"
                  patternUnits="userSpaceOnUse"
                  width="10"
                  height="10"
                  patternTransform={`rotate(${
                    (anchorState.angle * 180) / Math.PI
                  })`}
                >
                  <image
                    href="/material.jpg" /* Imaginea materialului pentru funie */
                    x="0"
                    y="0"
                    width="10"
                    height="10"
                  />
                </pattern>
              </defs>

              <line
                x1={anchorState.startX}
                y1={anchorState.startY}
                x2={anchorState.currentX}
                y2={anchorState.currentY}
                stroke="url(#rope-pattern)"
                strokeWidth="5"
              />
              <image
                href="/claw.png" /* Imaginea clestelui */
                x={anchorState.currentX - 15} /* Offset pentru centrare */
                y={anchorState.currentY - 15} /* Offset pentru centrare */
                width="30"
                height="30"
                transform={`rotate(${(anchorState.angle * 180) / Math.PI}, ${
                  anchorState.currentX
                }, ${anchorState.currentY})`}
              />
            </svg>
          )}
          {meteorites.map((meteorite, index) => (
            <div
              key={`${meteorite.id}-${index}`}
              className="stelar-meteorite"
              style={{
                position: "absolute",
                left: `${
                  meteorite.captured ? anchorState.currentX : meteorite.x
                }px`,
                top: `${
                  meteorite.captured ? anchorState.currentY : meteorite.y
                }px`,
                width: `${meteorite.width}px`,
                height: `${meteorite.height}px`,
                backgroundImage: `url(${meteorite.image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          ))}
        </section>
      )}
    </div>
  );
};

export default Stelar;
