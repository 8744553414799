import React, { useEffect, useRef } from "react";

const StelarBackground = () => {
  const canvasRef = useRef(null);
  const stars = useRef([]);
  const shootingStar = useRef(null);

  const layerCount = 3; // 3 layers for parallax
  const speeds = [0.05, 0.1, 0.2]; // Slower speeds for distant stars
  const baseStarCount = 30; // Reduced base count for performance

  // Resize the canvas
  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.width = canvas.parentElement.offsetWidth;
    canvas.height = canvas.parentElement.offsetHeight;
    createStars(canvas);
  };

  // Create the starfield
  const createStars = (canvas) => {
    stars.current = [];
    const scalingFactor = Math.max(canvas.width, canvas.height) / 1000;
    for (let i = 0; i < layerCount; i++) {
      const starCount = Math.floor(baseStarCount * scalingFactor * (i + 1));
      for (let j = 0; j < starCount; j++) {
        stars.current.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * (i + 1) + 0.5,
          speed: speeds[i],
          opacity: Math.random(),
          baseOpacity: Math.random() * 0.5 + 0.5,
        });
      }
    }
  };

  // Update star positions
  const updateStars = (canvas) => {
    stars.current.forEach((star) => {
      star.x -= star.speed; // Move stars left
      star.opacity =
        star.baseOpacity + Math.sin(Date.now() * 0.001 * star.speed) * 0.3;

      if (star.x < 0) {
        star.x = canvas.width; // Reset to the right
        star.y = Math.random() * canvas.height; // Random vertical position
      }
    });
  };

  // Initialize a shooting star
  const createShootingStar = (canvas) => {
    const startX = Math.random() * canvas.width;
    const startY = Math.random() * canvas.height;
    const length = Math.random() * 300 + 100;
    const speed = Math.random() * 4 + 2;

    shootingStar.current = {
      x: startX,
      y: startY,
      length,
      speed,
      opacity: 1,
      dx: -speed, // Always moves left
      dy: Math.random() * 2 - 1, // Slightly random vertical direction
    };

    // Schedule the next shooting star
    setTimeout(() => createShootingStar(canvas), Math.random() * 20000 + 20000);
  };

  // Update shooting star position
  const updateShootingStar = (canvas) => {
    if (!shootingStar.current) return;

    shootingStar.current.x += shootingStar.current.dx;
    shootingStar.current.y += shootingStar.current.dy;
    shootingStar.current.opacity -= 0.01;

    if (
      shootingStar.current.opacity <= 0 ||
      shootingStar.current.x < 0 ||
      shootingStar.current.y < 0 ||
      shootingStar.current.y > canvas.height
    ) {
      shootingStar.current = null; // Remove shooting star
    }
  };

  // Draw stars
  const drawStars = (ctx, canvas) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Add a dark radial blur gradient background
    const gradient = ctx.createRadialGradient(
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 8,
      canvas.width / 2,
      canvas.height / 2,
      canvas.width
    );
    
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw stars
    stars.current.forEach((star) => {
      ctx.fillStyle = `rgba(255, 255, 255, ${star.opacity})`;
      ctx.fillRect(star.x, star.y, star.size, star.size);
    });
  };

  // Draw the shooting star
  const drawShootingStar = (ctx) => {
    if (!shootingStar.current) return;

    const { x, y, dx, dy, length, opacity } = shootingStar.current;
    const gradient = ctx.createLinearGradient(
      x,
      y,
      x - dx * length,
      y - dy * length
    );
    gradient.addColorStop(0, `rgba(255, 255, 255, ${opacity})`);
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    ctx.beginPath();
    ctx.strokeStyle = gradient;
    ctx.lineWidth = 2;
    ctx.moveTo(x, y);
    ctx.lineTo(x - dx * length, y - dy * length);
    ctx.stroke();
    ctx.closePath();
  };

  // Animation loop
  const animate = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    updateStars(canvas);
    updateShootingStar(canvas);
    drawStars(ctx, canvas);
    drawShootingStar(ctx);

    requestAnimationFrame(animate);
  };

  // Initialize
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    resizeCanvas();
    createStars(canvas);
    setTimeout(() => createShootingStar(canvas), Math.random() * 20000 + 20000);
    animate();

    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  return <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />;
};

export default StelarBackground;
